import { SvgIconProps } from "@mui/material";

const NieuwsVanDeDagLogo: React.FC<SvgIconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="81" height="52" viewBox="0 0 81 52" fill="none">
    <path
      d="M24.9616 13.2586V17.8467C24.9616 19.608 25.9332 20.5009 27.8486 20.5009C28.9344 20.5009 29.8052 20.0182 30.2144 19.2168C30.2144 19.9714 30.2088 20.3015 30.2088 20.3015H33.8094C33.8094 20.3015 33.0629 19.7884 33.0629 19.1078V12.2166H29.3842C29.3842 12.2166 30.2144 12.5101 30.2144 13.2586V17.6802C30.2144 18.8075 29.7528 19.4805 28.9791 19.4805C28.2054 19.4805 27.8101 19.0481 27.8101 18.0791V12.2166H24.1951C24.1951 12.2166 24.961 12.6309 24.961 13.2586H24.9616Z"
      fill="white"
    />
    <path
      d="M34.7959 13.4781L37.3365 20.3019H38.8911L40.716 15.3437L42.5703 20.2516L42.5893 20.3019H44.1439L46.2568 14.533C46.5015 13.8708 46.7636 13.3933 47.0598 13.0725C47.5805 12.509 48.3228 12.217 48.3228 12.217H44.9664C44.9664 12.217 45.3612 12.3414 45.8692 13.0036C46.1175 13.328 46.1056 13.8626 45.8779 14.4785L44.7531 17.493L42.7461 12.217H41.4748L39.5008 17.5084L37.9662 13.4236C37.774 12.9213 38.2366 12.3517 38.5235 12.217H33.6547C33.6547 12.217 34.5229 12.7244 34.7949 13.4781H34.7959Z"
      fill="white"
    />
    <path
      d="M50.7349 17.5393C52.2833 17.9676 52.7557 18.3074 52.7557 18.9937C52.7557 19.68 52.1943 20.1293 51.2546 20.1293C49.2559 20.1293 48.0848 17.5234 48.0848 17.5234V20.3015C48.0848 20.3015 48.4318 19.9026 48.7613 19.9026C48.8451 19.9026 49.0847 19.9396 49.3942 20.0784C50.0244 20.3585 50.65 20.5009 51.2541 20.5009C53.0714 20.5009 54.3406 19.2934 54.3406 17.564C54.3406 16.1035 53.4353 15.1489 51.5733 14.6461C50.026 14.2379 49.5037 13.9048 49.5037 13.3254C49.5037 12.7044 50.0085 12.3893 51.0048 12.3893C52.7835 12.3893 53.9083 14.7464 53.9083 14.7464V12.2166C53.9083 12.2166 53.6579 12.632 53.2816 12.632C53.2749 12.632 53.1166 12.6304 52.783 12.4731C52.1419 12.1703 51.543 12.0166 51.0042 12.0166C49.1294 12.0166 47.9177 13.0849 47.9177 14.7376C47.9177 16.3904 49.0436 17.0792 50.7344 17.5388L50.7349 17.5393Z"
      fill="white"
    />
    <path d="M56.2884 0H53.589V6.32264H56.2884V0Z" fill="white" />
    <path d="M66.77 2.80812L63.6086 8.28369L65.9464 9.6334L69.1077 4.15783L66.77 2.80812Z" fill="white" />
    <path d="M76.78 11.8305L71.3044 14.9918L72.6542 17.3296L78.1297 14.1683L76.78 11.8305Z" fill="white" />
    <path d="M80.9385 24.65H74.6158V27.3494H80.9385V24.65Z" fill="white" />
    <path d="M72.6546 34.6697L71.3049 37.0074L76.7805 40.1688L78.1302 37.831L72.6546 34.6697Z" fill="white" />
    <path d="M65.9462 42.3666L63.6084 43.7163L66.7697 49.1919L69.1075 47.8422L65.9462 42.3666Z" fill="white" />
    <path d="M56.2879 45.6774H53.5885V52H56.2879V45.6774Z" fill="white" />
    <path
      d="M1.39366 18.5125C1.39366 19.682 0 20.3014 0 20.3014H3.17392C3.17392 20.3014 1.78231 19.682 1.78231 18.5125V11.1298L7.27883 20.3014H9.67803V10.9966C9.67803 9.82711 11.0696 9.20764 11.0696 9.20764H7.89572C7.89572 9.20764 9.28939 9.82711 9.28939 10.9966V17.7984L4.12547 9.20816H0.151653C0.151653 9.20816 0.798877 9.50221 1.08933 9.96128L1.39366 10.4394V18.513V18.5125Z"
      fill="white"
    />
    <path
      d="M14.6167 19.1078V12.2166H11.0187C11.0187 12.2166 11.7847 12.6309 11.7847 13.2586V19.1078C11.7847 19.7889 11.0187 20.3015 11.0187 20.3015H15.3457C15.3457 20.3015 14.6172 19.7884 14.6172 19.1078H14.6167Z"
      fill="white"
    />
    <path
      d="M13.2087 11.2254C14.065 11.2254 14.7591 10.5444 14.7591 9.70426C14.7591 8.86415 14.065 8.18311 13.2087 8.18311C12.3524 8.18311 11.6582 8.86415 11.6582 9.70426C11.6582 10.5444 12.3524 11.2254 13.2087 11.2254Z"
      fill="white"
    />
    <path
      d="M23.7262 16.0351V15.8145C23.7262 13.3305 22.4132 12.0181 19.9297 12.0181C17.4462 12.0181 15.7939 13.6467 15.7939 16.2679C15.7939 18.8892 17.3393 20.5009 19.9297 20.5009C23.1946 20.5009 23.6208 17.4041 23.6208 17.4041L23.4203 17.3619C23.2625 18.2374 22.2091 19.7729 20.599 19.7729C19.2013 19.7729 18.3037 18.0148 18.3037 16.0659V16.0351H23.7262ZM19.9297 12.3584C21.1388 12.3584 21.5506 13.8281 21.5506 15.6408H18.3088C18.3566 13.8667 18.7777 12.3584 19.9297 12.3584Z"
      fill="white"
    />
    <path
      d="M50.9045 34.2109L50.7379 34.1898C48.1752 33.8721 47.9609 33.5133 47.9609 33.173C47.9609 32.7396 48.7006 32.5103 50.1005 32.5103C52.913 32.5103 54.6598 31.2493 54.6598 29.2228C54.6973 27.8147 53.7 26.7002 52.018 26.218C54.1822 25.9101 55.4088 25.0783 55.4088 23.8939C55.4088 22.1234 53.3864 22.0427 52.712 22.3686C53.0765 22.4144 53.3104 23.0981 53.0682 23.9453C52.8359 24.7591 51.8833 25.9502 50.1005 25.9502C47.2494 25.9502 45.4784 27.203 45.4784 29.2197C45.4784 30.6329 46.487 31.7567 48.1495 32.2446C46.358 32.5396 45.2702 33.4172 45.2702 34.6078C45.2702 36.1228 46.4109 36.7983 49.6306 37.19L49.8172 37.2105C52.7022 37.5473 53.0718 38.3091 53.0718 38.9348C53.0718 40.0303 52.0946 40.6585 50.3914 40.6585C49.3561 40.6585 48.5675 40.4127 48.1099 39.9485C47.7958 39.6293 47.6406 39.2118 47.6483 38.7065C47.6483 38.3312 47.7342 37.9287 47.9115 37.4763L47.9593 37.3545L47.8303 37.3319C47.0026 37.1859 46.2002 37.3565 45.685 37.7889C45.337 38.0809 45.1453 38.4731 45.1453 38.8936C45.1453 40.4004 46.8546 41.1648 50.2249 41.1648C53.5952 41.1648 55.5127 39.7845 55.5127 37.5622C55.5127 35.6611 54.0049 34.5646 50.904 34.2104H50.9045V34.2109ZM50.101 26.4149C51.0109 26.4149 51.5332 27.4374 51.5332 29.2202C51.5332 31.0031 51.0109 32.0466 50.101 32.0466C49.1911 32.0466 48.6482 31.0164 48.6482 29.2202C48.6482 27.424 49.1777 26.4149 50.101 26.4149Z"
      fill="white"
    />
    <path
      d="M38.3502 30.6175C34.9866 30.6175 33.0573 31.7634 33.0573 33.7611C33.0573 35.5054 34.3692 36.5886 36.4815 36.5886C37.996 36.5886 39.1229 35.9413 39.6441 34.7939C39.7675 35.9388 40.6836 36.5886 42.1991 36.5886C43.4308 36.5886 44.2472 36.0323 44.5788 34.9965C44.3089 35.29 42.7199 35.7306 42.7199 34.267V29.2079C42.7199 27.0992 41.0404 25.9374 37.9898 25.9374C35.2662 25.9374 33.5749 26.819 33.5749 28.2379C33.5749 28.7257 33.7631 29.1391 34.1188 29.4341C34.6098 29.8413 35.3907 29.9971 36.3808 29.884L36.4908 29.8716L36.4584 29.7719C36.316 29.3334 36.2373 28.8332 36.2373 28.3638C36.2373 27.3686 36.7725 26.3646 37.9672 26.3646C39.3413 26.3646 39.6297 27.5552 39.6297 28.554V30.6165H38.3497V30.6175H38.3502ZM39.6303 31.0874V32.5597C39.6303 34.3929 38.9758 35.4021 37.7878 35.4021C36.7602 35.4021 36.1705 34.6428 36.1705 33.3185C36.1705 31.8585 37.0568 31.0869 38.7337 31.0869H39.6308L39.6303 31.0874Z"
      fill="white"
    />
    <path
      d="M8.28183 24.8346C6.6558 24.8346 5.72327 25.4212 5.72327 26.4432C5.72327 27.3356 6.35764 27.8898 7.3786 27.8898C8.11064 27.8898 8.65557 27.5587 8.90746 26.9717C8.9671 27.5577 9.40972 27.8898 10.1423 27.8898C10.7376 27.8898 11.1324 27.605 11.2928 27.075C11.1622 27.2251 10.3942 27.4508 10.3942 26.7018V24.1134C10.3942 23.0343 9.58245 22.4401 8.10756 22.4401C6.79101 22.4401 5.97362 22.8909 5.97362 23.6173C5.97362 23.8666 6.06461 24.0784 6.23632 24.2296C6.47382 24.4378 6.85115 24.5175 7.32976 24.4599L7.38322 24.4537L7.36729 24.4028C7.2984 24.1787 7.26036 23.9227 7.26036 23.6826C7.26036 23.1731 7.51894 22.6596 8.09676 22.6596C8.76095 22.6596 8.90027 23.2688 8.90027 23.7798V24.8352H8.28132H8.28183V24.8346ZM8.90027 25.0747V25.8284C8.90027 26.766 8.58411 27.2827 8.00937 27.2827C7.51277 27.2827 7.22746 26.894 7.22746 26.2165C7.22746 25.4695 7.65569 25.0747 8.46639 25.0747H8.90027Z"
      fill="white"
    />
    <path
      d="M10.9916 35.6194V28.5709H8.99899C8.99899 28.5709 9.48428 28.8871 9.48428 29.2372V31.7454C9.19845 31.2035 8.65353 30.9084 7.92714 30.9084C6.44299 30.9084 5.62561 31.8883 5.62561 33.668C5.62561 35.4477 6.44299 36.4168 7.92714 36.4168C8.62834 36.4168 9.19537 36.0795 9.48428 35.5033V36.2862H11.4768C11.4768 36.2862 10.9916 35.9778 10.9916 35.6194ZM9.48428 32.8177V34.4201C9.48428 35.3516 9.03343 35.9778 8.36307 35.9778C7.56831 35.9778 7.16527 35.2005 7.16527 33.668C7.16527 32.1355 7.59453 31.3259 8.40677 31.3259C9.06119 31.3259 9.48428 31.9114 9.48428 32.8177Z"
      fill="white"
    />
    <path
      d="M24.4928 22.365H17.5825C17.5825 22.365 19.0805 22.9644 19.0805 23.9956V34.6386C19.0805 35.6698 17.5825 36.2693 17.5825 36.2693H19.0805C19.5509 36.2693 24.4162 36.2836 24.5555 36.2693C29.2413 36.2693 32.1525 33.6053 32.1525 29.3174C32.1525 25.0295 29.2176 22.3655 24.4933 22.3655H24.4928V22.365ZM22.9567 22.8708H23.8481C26.618 22.8708 27.9638 24.9796 27.9638 29.3169C27.9638 33.6541 26.6421 35.7629 24.0352 35.7629H22.9567V22.8708Z"
      fill="white"
    />
    <path
      d="M15.0306 27.7695C15.0306 27.7695 15.5158 27.4621 15.5158 27.1028V24.2142C15.5158 23.3839 15.1786 22.9074 14.591 22.9074C14.0034 22.9074 13.5459 23.5299 13.5459 24.4213V27.1028C13.5459 27.4616 14.0312 27.7695 14.0312 27.7695H11.5641C11.5641 27.7695 12.0494 27.4621 12.0494 27.1028V23.1999C12.0494 22.7804 11.4659 22.5223 11.4659 22.5223H13.5459V23.368C13.8266 22.7675 14.4389 22.3912 15.1688 22.3912C16.3235 22.3912 17.0128 23.1048 17.0128 24.3005V27.1022C17.0128 27.4611 17.4981 27.769 17.4981 27.769H15.0306V27.7695Z"
      fill="white"
    />
    <path
      d="M3.98359 22.5223L4.02831 22.5419C4.11211 22.583 4.3054 22.7028 4.53828 23.0061C4.69096 23.205 4.68993 23.534 4.5501 23.9119L3.58878 26.325L2.42131 23.3001C2.2861 22.7819 2.85673 22.5223 2.85673 22.5223H0L0.013366 22.529C0.100245 22.5722 0.636942 22.8524 0.798877 23.3001L2.53286 27.769H3.27056L4.84056 23.7834C4.8421 23.7798 4.84313 23.7762 4.84467 23.7726C4.97576 23.4544 5.11559 23.2158 5.27033 23.0482C5.59008 22.7018 5.9047 22.5229 5.9047 22.5229H3.98359V22.5223Z"
      fill="white"
    />
    <path
      d="M16.6808 33.5595V33.4141C16.6808 31.7752 15.8146 30.9084 14.1752 30.9084C12.5358 30.9084 11.4459 31.9829 11.4459 33.7127C11.4459 35.4426 12.4659 36.5062 14.1752 36.5062C16.3297 36.5062 16.6109 34.4628 16.6109 34.4628L16.4787 34.435C16.3749 35.0128 15.6799 36.0261 14.6168 36.0261C13.6945 36.0261 13.1018 34.8658 13.1018 33.5801V33.5595H16.6808ZM14.1757 31.1336C14.9735 31.1336 15.2455 32.1037 15.2455 33.2994H13.1064C13.1383 32.1289 13.4159 31.1336 14.1757 31.1336Z"
      fill="white"
    />
  </svg>
);

export default NieuwsVanDeDagLogo;
